import React from "react";
import MDEditor from '@uiw/react-markdown-editor';
import type { IMarkdownEditor } from '@uiw/react-markdown-editor';
import { EditorView } from "@codemirror/view";

interface EditorProps {
  value: string;
  className?: string;
  onChange: (value: string) => void;
}

export const Editor = ({ value, className, onChange }: EditorProps) => {
  return (
    <MDEditor
      className={className}
      value={value || "\n\n\n\n"} // Handle null/undefined case
      extensions={[EditorView.lineWrapping]}
      height="400px"
      onChange={(e) => onChange(e ?? '')}
      enablePreview={false}
      visible
    />
  );
}
